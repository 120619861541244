
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
li {
   list-style-type: none; 
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height:10vh;
  box-shadow: rgba(50, 50, 93, 0.23) 0px 30px 30px -20px;
  background-color: #FFF;


  box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}



.logo {
  font-size: 20px;
  
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}

.nav-links li {
  color: black;
  font-size: 15px;
  padding: 10px 10px;
  margin: 0 10px;
  cursor: pointer;
   text-transform: capitalize; 
}
.hero {
  text-align: center;
  margin-top: 15%;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  border-radius: 5px;
  background: none;
}
@media (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    /* box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
  }
 ul li {
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
  .hero h1 {
    font-size: 50px;
  }
}
