/* .rowClass>tr:nth-child(even) {
    background-color: #edecec !important;
  }
  .rowClass>tr:nth-child(odd) {
    background-color: #ffffff !important;
  } */

  /* .even-row {
  background-color: #edecec !important;
}

.odd-row {
  background-color: #ffffff !important;
} */
.even-row {
  background-color: #f2f2f2; /* Light gray background for even rows */
}

.odd-row {
  background-color: #ffffff; /* White background for odd rows */
}