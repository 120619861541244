* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
body {
  /* background-color: #fcf2db; */
  /* background-image: url(./AllProjectBackground.png); */
  background-color:#e5e5e5;
  background-size: 1000px 600px;
}

#image {
  height: 200px;
  width: 200px;
  /* background-image: url(./AllProjectBackground.png); */
  background-size: 200px 200px;
}
.container {
  display: flex;
}
main {
  width: 100%;
  padding: 50px;
}
.sidebar {
  background: #ffff;
  color: black !important;
  height: 120vh !important;
  /* width:100vw; */
  /* transition: all 0.5s; */
  position: absolute;
  z-index: 2;
}
/* .collapseLable{
  transition: all 0.5s !important;
} */
.tooltip .tooltiptext {
  width: 228px !important;
  padding: 6px !important;
 
}
.navigations {
  margin-left: 50px;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 30px;
}

#bars {
  /* display: flex;
    font-size: 25px;
    margin-left: 50px; */

  display: flex;
  font-size: 20px;
  margin-left: 40px;
  position: absolute  ;
  align-items: center;
  padding: 0.938rem 0.938rem 0 0.738rem;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
}
/* .link {
  display: flex;
  color: #fff;
  padding: 7px 13px;
 gap: 10px;
 transition: all 0.5s;
  color: black;
  font-size: x-large;
  
} */

.link {
  display: flex;
  color: black;
  padding: 10px 12px;
  gap: 15px;
  font-size: x-large;
  height: 100% !important;

  transition: all 0.5s;
}

.link:hover {
  transition: all 0.5s;
}
/* .active{
  height:20px;
  
  width:27px;
 
  position: relative;
  background:hsl(224, 56%, 28%) !important;
  color:white;
  z-index: 1;
  bottom:-16%;
  left: 125%;
  opacity: 0;
  transition: opacity 0.3s;;

}
 

 .icons{
  height:20px;
  width:27px;
  color:black;
  position: relative;
  
 
  

} */
.icon,
.link_text {
  font-size: 17px !important;

  /* color:black; */
}

/* 
 .link:hover {

  border-radius: 5px !important;
  color: white;
  transition: all 0.5s;
 
} */

.active {
  color: #fff;
  background-color: #d04a02 !important;
  /* height: 10px !important;
  width:20px!important;
  */
}
/* .active:hover {
  color: #fff;
  background-color: #d04a02 !important;
} */
.highlight:hover{
  filter: brightness(4.5); 
}
.active .highlight{
  filter: brightness(4.5); 
}
/* .link_text {
  font-size: 20px;
  height:5%;
  color: black;
 
  
}  */

.sectionCard {
  font-weight: bold;
  padding: 8px;
  border: 1px solid;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: white;
  width: 100%;
}

.demo {
  background-color: white !important;
  color: black !important;
  margin-top: "4em" !important;
}

.demo:hover {
  background-color: #d04a02 !important;

  color: white !important;
}

.uploadButton {
  background-color: white !important;
  border: none !important;
  color: black !important;
}

.popup {
  height: 220px;
  width: 20%;
  background-color: white;
  position: absolute;
  top: 42%;
  left: 40%;
}

.searchbox {
  display: flex;
  gap: 6px;
  background-color: white;
  align-items: center;
  margin-left: 20%;
  width: 50%;
  height: 40%;
  padding: 4px;
}
input {
  border: none;
  outline: none;
  background-color: transparent;
}
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.word {
  padding: 0 8px;
  background-color: white;
  border-color: black;
  margin-left: 20%;
  height: 30px;
  width: 50%;
}
.word:hover {
  background-color: lightblue;
}
.paginator-demo {
  font-weight: italic;
}

.box {
  border: 1px solid #747272 !important;
  padding: 5px;
  height: 40px;
  border-radius: 5px;
  border-spacing: 0 15px;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #eb8c00 !important;
  /* background: #ffb600 !important; */
  /* border-radius: 5px!important; */
  padding: 0.2rem 1rem 0.2rem 1rem !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 1rem 0.3rem 1rem !important;
}

/* ALL DATATABLE SERACH BOX */
.p-input-icon-left > .p-inputtext{
  padding-left: 2rem !important;
  /* height: 10% !important; */
  height: 32px !important;
  width: 90%;
  border: 1px solid #d04a02;

  border-radius: 2px !important;
}
.p-input-icon-left > .p-inputtext:hover{
  border: 1px solid #d04a02;
}
.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: 0.75rem;

  color: #6c757d;
}

/* ALL BUTTON */
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
  height: 30px !important;
  /* height: 24px !important;
  width: */
}

.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  /* padding: 1rem 1rem 1rem !important; */
  font-weight: 600 !important;
}
/* 
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2rem !important;
} */
.p-icon {
  display: inline-block;
  /* color: white; */
}
/* .p-dropdown .p-dropdown-label.p-placeholder {
  color: rgb(255, 255, 255) !important;
  padding-bottom: -17px !important;
  align-items: center !important;
} */
.p-dropdown .p-dropdown-trigger{
  color: white !important;
  /* border:  1px solid black; */
  width: fit-content !important;
  padding: 0 5px;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 2rem !important;
  height: 2rem !important;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-tabview-nav-container {
  position: relative;
}

.p-card .p-card-content {
  padding: 0.25rem 0 !important;
  padding-bottom: 1rem !important;
}

.p-tabview-nav {
  height: 40px !important;
}

/* .p-unselectable-text {
  height: 40px !important;
  margin-top: -10px;
  
} */

.tabview-header {
  /* padding: 2px; */
  margin-top: -10px;

  /* margin-bottom: -30px; */
}
.p-tabview .p-tabview-nav {
  background: #ffffff;

  border: none !important;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 0rem !important;
  border: none !important;

  color: #495057;

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
/* .p-card .p-card-body {
  padding: 0.25rem;
} */
/* .p-card .p-card-body {
  padding: 0.25rem!important;;
} */
/* 
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Helvetica;
} */

a {
  text-decoration: none;
}
li {
  list-style-type: none;

}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
  box-shadow: rgba(50, 50, 93, 0.23) 0px 30px 30px -20px;
  background-color: #fff;

  box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.logo {
  font-size: 20px;
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}

.nav-links li {
  color: black;
  font-size: 15px;
  padding: 10px 10px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.hero {
  text-align: center;
  margin-top: 15%;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  border-radius: 5px;
  background: none;
}
@media (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    top: 15px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;

    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
  }
  ul li {
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
    /* width:50px!important; */
  }
  .hero h1 {
    font-size: 50px;
  }
}
/* //tabview style */

/* .icons :hover{
width: 20px;

} */



/* .p-tabview-nav-link{
  border-color: 1px solid rgb(216, 22, 54) !important;

} */

/* /Accordion */

/* .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.70rem !important;
  border: 1px solid #dee2e6!important;
  color: #6c757d;
  background: #f8f9fa;
  font-weight: 500;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem !important;
} */
/* 
.image:hover {
  color: white!important;
 
} */

/* 
.p-datatable .p-paginator-bottom {
  border-width: 0 0 -10px 0!important;
  border-radius: 0;
} */
/* Choose button Code */
.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: -0.1rem 0rem !important;
  border-radius: 6px;
}

/* /INPUTTEXT/ */

.p-fluid .p-inputtext {
  /* width: 78%; */
  border-radius: 2px !important;
}

.tooltip {
  /* background-color:white; */
  /* color:black; */
  position: relative;
  border-radius: 2px;
}

.tooltip:hover {
  /* background:white;
  color:black; */
  /* height: 27px;  */
  padding: 2px;

  background: #d04a02 !important;
  color: white;
  /* 
  width:45px; */
  /* height:32px; */
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  height: 25px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 190px;
  display: "flex";
  background-color: #eb8c00;
  color: black;
  text-align: center;
  font-size: 20px !important;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: -16%;
  height: 40px;
  left: 125%;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when hovering over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.bookmarkTabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
   /* background-color: #d04a02 !important; */
  color: white !important;
  /* border-radius: 5px !important;
  margin-top: 9px !important;
  height: 10% !important;  */
  border-color: #d04a02 !important;
  border-width: 100% !important;
  border-radius: 0px !important;
  border: 1px solid #d04a02!important;
}

.p-tabview .p-tabview-nav {
  width: fit-content !important;
}
/* .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  height: 2px;
  background-color: #d04a02 !important;
  color: #d04a02 !important;
} */
